import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import { HTMLContent } from '../components/Content'
import { SubPageLayout } from '../components/SubPageLayout'
import NovatedLeaseForm from '../components/Forms/novated-lease-form'
import { CTAContactForm } from '../helpers'

export const SidebarPageTemplate = ({ content }) => (
  <SubPageLayout>
    <CTAContactForm />
    <div className="columns">
      <div className="column">
        <HTMLContent content={content} />
      </div>
      <div className="column is-3">
        <div className="box">
          <NovatedLeaseForm />
        </div>
      </div>
    </div>
    <br />
  </SubPageLayout>
)

const Page = ({ data, pageContext }) => {
  const { frontmatter, html } = data.markdownRemark
  return (
    <Layout
      withHeader={{ ...frontmatter, ...pageContext }}
      pageContext={pageContext}
    >
      <SidebarPageTemplate content={html} />
    </Layout>
  )
}

Page.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default Page

export const sidebarPagePageQuery = graphql`
  query SidebarPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        heading
        image {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid
              presentationWidth
            }
          }
        }
      }
    }
  }
`
