import React from 'react'
import Form from './'

const novatedLeaseFormFields = [
  {
    label: 'Name',
    required: true,
  },
  {
    label: 'Company',
    required: true,
  },
  {
    label: 'Suburb',
    required: true,
  },
  {
    label: 'Postcode',
    type: 'number',
    required: true,
  },
  {
    label: 'Phone',
    required: true,
  },
  {
    label: 'Email',
    type: 'email',
    required: true,
  },
  {
    label: 'Vehicle(s)',
    required: true,
  },
  {
    label: 'Contract length',
  },
  {
    label: 'Kms per year',
  },
  {
    label: 'Other info',
    type: 'textarea',
  },
]

const LeasingForm = () => (
  <>
    <h3 className="title is-3">Quick Quote</h3>
    <Form formFields={novatedLeaseFormFields} formName={'Leasing Form'} />
  </>
)

export default LeasingForm
